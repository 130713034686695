<template>
  <li class="navigation__item navigation__item-has-children">
    <transition-group tag="ul" class="navigation__inner" name="trs-list">
      <li
        v-for="patient in patients"
        :key="patient.id"
        class="navigation__item navigation__item--profile"
        :class="{ 'navigation__item--profile--active': isActive(patient.id) }"
      >
        <a :href="getActiveURLIfExist(patient.id)" class="navigation__link">
          {{ patient.initials }}
        </a>
      </li>
    </transition-group>
  </li>
</template>

<script lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, getCurrentInstance } from '@vue/composition-api';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { apolloClient } from '@/plugins/apollo-client';
import { gql } from '@apollo/client/core';
import patientIdParam from './services/patient-id-param';
import type { PatientQuickLinkInfoQuery, PatientQuickLinkInfoQueryVariables } from '@/graphql/generated/graphql';
interface PatientCache {
  [key: string]: {
    id: string;
    initials: string;
    displayName: string;
    pictureUrl: string;
  };
}
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const patientId = ref<string | undefined>(undefined);
  const patientIds = ref<string[]>([]);
  const patientCache = ref<PatientCache>({});
  const patients = computed(() => {
    return patientIds.value.map(id => patientCache.value[id]).filter(e => e);
  });
  const isActive = (id: string): boolean => {
    return patientId.value === id;
  };
  const persistPatientIds = (): void => {
    window.localStorage.setItem('recentPatientIds', JSON.stringify(patientIds.value));
  };
  const recordPatient = (id: string): void => {
    patientId.value = id;
    if (!patientIds.value.includes(id)) {
      patientIds.value = [id, ...patientIds.value.slice(0, 2)];
      persistPatientIds();
    }
  };
  const fetchPatient = async (id: string): Promise<void> => {
    const {
      onResult,
      onError
    } = provideApolloClient(apolloClient)(() => useQuery<PatientQuickLinkInfoQuery, PatientQuickLinkInfoQueryVariables>(gql`
        query PatientQuickLinkInfo($id: ID!) {
          patient(id: $id) {
            id
            initials
            displayName
            pictureUrl(size: "thumb")
          }
        }
      `, {
      id
    }, {
      fetchPolicy: 'no-cache'
    }));
    onResult(({
      data
    }) => {
      if (data?.patient) {
        const {
          initials,
          displayName,
          pictureUrl
        } = data.patient;
        patientCache.value = {
          ...patientCache.value,
          [id]: {
            id,
            initials,
            displayName,
            pictureUrl
          }
        };
      }
    });
    onError(() => {
      patientIds.value = patientIds.value.filter(patientId => patientId !== id);
      persistPatientIds();
      localStorage.removeItem(`recentPatientIdObject-${id}`);
    });
  };
  const getActiveURLIfExist = (id: string): string => {
    const currentPatientObj = JSON.parse(localStorage.getItem(`recentPatientIdObject-${id}`));
    return id && currentPatientObj ? currentPatientObj.pathname : `/patients/${id}`;
  };
  const refresh = (): void => {
    patientIds.value = JSON.parse(window.localStorage.getItem('recentPatientIds') || '[]');
    const currentPatientId = patientIdParam();
    if (currentPatientId) {
      recordPatient(currentPatientId);
    } else {
      patientId.value = undefined;
    }
    patientIds.value.forEach(id => fetchPatient(id));
  };
  onMounted(() => {
    document.addEventListener('turbolinks:load', refresh);
    const {
      proxy
    } = getCurrentInstance();
    proxy.$eventBus.$on('refreshRecentPatientIds', refresh);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('turbolinks:load', refresh);
    const {
      proxy
    } = getCurrentInstance();
    proxy.$eventBus.$off('refreshRecentPatientIds', refresh);
  });
  return {
    patients,
    isActive,
    getActiveURLIfExist
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.navigation__list--profiles {
  // Vars
  /*
  * 48px width + 8px margin = 56px * 4 item = 224px
  * 3 is visible in the UI and 1 is just for spacing
  */
  --list-height: 224px;
  --box-shadow-outline: 0 0 0 4px;

  // Support

  // Module
  & {
    .navigation__inner {
      height: var(--list-height);
    }

    .navigation__item-has-children {
      overflow: visible;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .navigation__item--profile {
      border: 1px solid var(--c-nav-hover);
      background-color: inherit;
    }
  }

  // Facets
  & {
    .navigation__item--profile--active {
      background-color: var(--v-primary-base);
      border-color: var(--v-primary-base);
    }
  }

  // States
  & {
    .navigation__item--profile:hover {
      background-color: var(--c-nav-hover);
      border-color: var(--c-nav-hover);
    }

    .navigation__item--profile--active:hover {
      background-color: var(--v-primary-base);
      border-color: var(--v-primary-base);
    }
  }
}

// Transitions
.navigation__item--profile {
  transition: transform 0.4s, opacity 0.4s;
  display: inline-block;
}
.trs-list-enter,
.trs-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.trs-list-leave-active {
  position: absolute;
}
</style>
