import moment from 'moment'
import { numberWithDelimiter, numberToCurrency } from '../../libs/number-format'

function currencyFilter() {
  return (value) => {
    if (value === undefined || value === null || value === '' || isNaN(value)) {
      return ''
    }

    return numberToCurrency(value)
  }
}

function numberFilter() {
  return (value, decimals = 2) => {
    if (value === undefined || value === null || value === '') {
      return null
    }
    if (Object.is(value, -0)) value = 0
    return numberWithDelimiter(value, decimals)
  }
}

function dateFilter() {
  return (value, format = 'L') => (value ? moment(value).format(format) : null)
}

function timeFilter() {
  return (value, format = 'LT') => (value ? moment(value).format(format) : null)
}

function dateTimeFilter() {
  return (value, format = 'L, LT') =>
    value ? moment(value).format(format) : null
}

function ageFilter() {
  return (value) => {
    const diff = moment.duration(moment().diff(value))
    const years = Math.floor(diff.years())
    const months = Math.floor(diff.months())

    let age = `${years}J`
    if (months != 0) {
      age += `${months.toString().padStart(2)}M`
    }

    return age
  }
}

function differentInDaysFilter() {
  return (value) => {
    let given = moment(value, 'YYYY-MM-DD')
    let current = moment().startOf('day')
    return Math.round(moment.duration(given.diff(current)).asDays())
  }
}

export default {
  number: numberFilter,
  currency: currencyFilter,
  date: dateFilter,
  time: timeFilter,
  age: ageFilter,
  dateTime: dateTimeFilter,
  differentInDays: differentInDaysFilter,
}
