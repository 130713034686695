import compact from 'lodash/compact'
import deburr from 'lodash/deburr'
import flatten from 'lodash/flatten'
import toString from 'lodash/toString'
import remove from 'lodash/remove'

const flattenCompact = (array) => {
  return flatten(compact(array))
}

const SLICE_SIZE = 50

export class DInputBusinessObjectFilter {
  constructor(items) {
    const {
      ntariffs = [],
      sub_tariffs = [],
      tartec = [],
      tariffs = [],
      samples = [],
      articles = [],
      labs = [],
      discounts = [],
      fee_items = [],
    } = items

    this.ntariffs = ntariffs
    this.sub_tariffs = sub_tariffs
    this.tartec = tartec
    this.tariffs = tariffs

    this.samples = samples
    this.articles = articles
    this.labs = labs
    this.discounts = discounts
    this.fee_items = fee_items

    this.tariffType = 'Ntariff'
    this.parentBusinessObjectId = null
    this.query = ''
  }

  get isSubTariff() {
    return !!this.parentBusinessObjectId
  }

  get items() {
    return flattenCompact([
      this.ntariffs,
      this.sub_tariffs,
      this.tartec,
      this.tariffs,
      this.samples,
      this.articles,
      this.labs,
      this.discounts,
      this.fee_items,
    ])
  }

  get selectedItem() {
    if (this.isSubTariff) {
      return this.prepareSubtariffs.find(
        (item) => item.poly_id === this.selectedItemId
      )
    }

    return this.items.find((item) => item.poly_id === this.selectedItemId)
  }

  sortAlphaNumeric(items) {
    return items.sort((left, right) =>
      toString(left.label).localeCompare(toString(right.label), {
        ignorePunctuation: true,
        sensitivity: 'base',
        usage: 'sort',
      })
    )
  }

  filterByQuery(items) {
    const filteredItems = items
      .filter((item) => this.cleanString(item.label).includes(this.query))
      .slice(0, SLICE_SIZE)

    return this.sortAlphaNumeric(filteredItems)
  }

  filterParentItems(sortingOrder) {
    const tariffs = this.tariffType === 'Ntariff' ? this.ntariffs : this.tariffs
    const tartecTariffs = this.tariffType === 'Ntariff' ? this.tartec : []

    const items = flattenCompact([
      this.filterByQuery(this.samples),
      this.filterByQuery(this.discounts),
      this.filterByQuery(tariffs),
      this.filterByQuery(this.fee_items),
      this.filterByQuery(this.articles),
      this.filterByQuery(this.labs),
      this.filterByQuery(tartecTariffs),
    ])

    let sortedItems = items.sort((left, right) => {
      const leftIndex = this.cleanString(left.label).indexOf(this.query)
      const rightIndex = this.cleanString(right.label).indexOf(this.query)

      return leftIndex - rightIndex
    })

    if (sortingOrder) {
      sortedItems = sortedItems.sort(
        (left, right) =>
          sortingOrder.indexOf(left.model) - sortingOrder.indexOf(right.model)
      )
    }

    if (this.selectedItem)
      remove(sortedItems, (item) => item.poly_id === this.selectedItemId)
    return flattenCompact([this.selectedItem, sortedItems]).slice(0, SLICE_SIZE)
  }

  cleanString(string) {
    return deburr(toString(string)).replace(/\./g, '').toLowerCase()
  }

  get prepareSubtariffs() {
    const businessObject = this.items.find(
      (item) => item.poly_id === this.parentBusinessObjectId
    )

    const materialForBusinessObject =
      businessObject &&
      this.sub_tariffs.find((item) =>
        item.label.includes(`${businessObject.code}.M`)
      )

    // support for Dentotar LP+ (Tartec & LP 2025) which have chapters set and have subpositions that start with `+`
    // which are TP positions (not material positions)
    const lPs = this.sub_tariffs.filter(
      (item) => businessObject.chapter && item.chapter == businessObject.chapter
    )

    const preparedSubtariffs = this.sub_tariffs
      .filter(
        (item) => item.label.startsWith('4.xxxx') || item.label.includes('yyyy')
      )
      .map((item) => {
        if (item.label.includes('4.xxxx')) {
          return {
            ...item,
            label: item.label.replace('4.xxxx', businessObject.code),
          }
        }

        if (item.label.includes('yyyy'))
          return {
            ...item,
            label: item.label.replace(
              'yyyy',
              businessObject.code?.split('.')[1]
            ),
          }

        return item
      })

    return compact([materialForBusinessObject, ...preparedSubtariffs, ...lPs])
  }

  filterSubTariffItems() {
    const subtariffs = this.filterByQuery(this.prepareSubtariffs)
    if (this.selectedItem) this.removeSelectedItem(subtariffs)
    return flattenCompact([this.selectedItem, subtariffs]).slice(0, SLICE_SIZE)
  }

  removeSelectedItem(items) {
    const idx = items.findIndex((item) => item.id === this.selectedItem.id)
    items.splice(idx, 1)
  }

  result(
    tariffType,
    parentBusinessObjectId,
    query = '',
    selectedItemId = null,
    sortingOrder = null
  ) {
    this.tariffType = tariffType
    this.parentBusinessObjectId = parentBusinessObjectId
    this.query = this.cleanString(query)
    this.selectedItemId = selectedItemId

    if (this.isSubTariff) return this.filterSubTariffItems()

    return this.filterParentItems(sortingOrder)
  }
}
